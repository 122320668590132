@import "../../css/colors.module";
.MainLayout {
}
.header {
  margin-top: 120px;
}

.backgroundImage {
  position: absolute;
  height: 50vh;
  background-blend-mode: darken;
  background: rgba(0, 0, 0, 0.65);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
}
