@import "../../css/colors.module";
.offest {
  position: absolute;
  top: -25px;
}

.container {
  position: relative;
  width: 100%;
  color: $primary;
  display: flex;
  justify-content: center;
  height: 50vh;
  color: $dark;
}

.title {
  font-weight: 800;
  font-size: 60px;
  text-decoration: underline;
  text-transform: uppercase;
}

.dark {
  color: black;
}

.light {
  color: white;
}

.primary {
  color: $primary;
}
