.container {
  margin-top: 30px;
  .item {
    .img {
      margin: 0px 10px;
    }
  }
}

#imgLeft {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

#imgRight {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

#middle {
  @media screen and (max-width: 1500px) {
    display: none;
  }
}
