@import "../../css/colors.module";
.FWCcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8% 0px;
  height: 100%;
  text-align: center;
  .title {
    color: $primary;
    margin-bottom: 50px;
    text-decoration: underline;
  }
  p {
    margin: 0px 10%;
  }
  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 950px) {
  }

  @media screen and (max-width: 500px) {
    p {
      margin: 0px 15%;
    }
  }
}
