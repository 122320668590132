.container {
  .carousel {
    display: flex;
    justify-content: center;
    background-color: #1d2226;
  }

  .cardContainer {
  }
}
