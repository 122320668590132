@import "../../css/colors.module";

.container {
  padding: 8% 0px;
  height: 100%;
  display: flex;
  .secondaryContainer {
  }

  .left {
    display: flex;
    margin-left: 15%;
    .L2Background {
      background-color: $primary;
      height: 50vh;
      width: 50vh;
      position: absolute;
      margin-left: 20px;
      margin-top: 20px;
    }
    .image {
      height: 50vh;
      width: 55vh;
      background-size: 90%;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }
  .right {
    text-align: center;
    .paragraph {
      margin-top: -50px;
      width: 35%;
      position: absolute;
      right: 10%;
    }
  }
}

.lgContainer {
  display: flex;
  flex-direction: column;
  .lgSecondaryContainer {
  }

  .lgLeft {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
    .L2Background {
      background-color: $primary;
      height: 50vh;
      width: 50vh;
    }
    .lgImage {
      height: 50vh;
      width: 55vh;
      background-size: 90%;
      background-repeat: no-repeat;
    }
  }
  .lgRight {
    margin: 10% 50px;
    display: flex;
    text-align: center;

    .lgParagraph {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.smContainer {
  display: flex;
  flex-direction: column;
  .smSecondaryContainer {
  }

  .smLeft {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;

    .smImage {
      height: 40vh;
      width: 45vh;

      background-size: 90%;
      background-repeat: no-repeat;
    }
  }
  .smRight {
    margin: 10% 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    .lgParagraph {
      width: 80%;
    }
  }
}
